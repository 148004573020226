import React, { Component } from "react";
import { FiMonitor, FiWind, FiTrello, FiFastForward } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiWind />,
        title: 'Branding',
        description: '+150 worldwide websites with qualified content to attract the interest of your potential customers.'
    },
    {
        icon: <FiTrello />,
        title: 'Business Intelligence',
        description: 'Analysis of the traffic sent to your website, measuring quality and first-impact conversions.'
    },
    {
        icon: <FiFastForward />,
        title: 'Retargeting',
        description: 'Our technology takes the field to work the second impact and bring your customer back, enriching the conversion process.'
    },
    {
        icon: <FiMonitor />,
        title: 'Marketing & Reporting',
        description: 'How skilled was the branding used versus how much retargeting was needed to retrieve users and how can we fine-tune the entire process.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'We offer full trajectory services, from the first contact with the client until their conversion.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            {/*<div className="service-btn">*/}
                            {/*    <a className="btn-transparent rn-btn-dark" href="/#contact-area"><span className="text">Request Custom Service</span></a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
