import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const Privacy = () => {
    return (
        <>
            <PageHelmet pageTitle='Privacy Policy' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" logo="light" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">Privacy Policy</h2>
                                <p>This policy includes important information and we encourage you to read it carefully.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <p>Zetto does not collect or use any type of data through the script, be it first name, last name, postal address, email address or any other. We are GDPR compliant and that's why we don't capture or use anything private regarding users who come across our technology.</p>

                                    <p>When you browse an advertiser's website that uses our technology, our script just tags you with a unique ID so we can connect you to the other end, which will be the publisher.
                                        When you browse a website of a publisher that uses our technology, our script loads and identifies which of these users have already crossed paths with our script before, making it possible to print a contextual ad, that is, one that goes according to the user's past navigation. This makes the user experience more meaningful and more enjoyable.</p>

                                    <p>It is worth mentioning that our technology, both on the websites of advertisers and publishers, is governed by their privacy policy.
                                        That's why we always ask our partners to be clear with all the information passed on to users who access, obtaining consent before activating our technology.</p>

                                    <p>Many market players use more information than necessary, seeking complete ad customization. Admasters only needs to tag its users to impact them with the correct ad, thus contributing to the best user experience, who will receive assertive ads on their screen and also benefiting publishers in the monetization of their precious content.</p>

                                    <h4 className="tilte">Changes to our privacy policy:</h4>

                                    <p>Our policy is subject to updates and changes.
                                        Whenever this occurs, we will publish it with our publishers and pass the complete information on to advertisers using our technology before the new policy takes effect.</p>

                                    <h5 className="tilte">Last update: 10/12/2021 </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Privacy;