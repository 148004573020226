import React, { Component } from "react";

class About extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p>Master of the whole conversion funnel, Zetto was founded on performance marketing and found itself so comprehensive that it expanded into business analytics and branding.
                                            With 10 years in the market, we continue to impress our customers in terms of conversion rate and transparency.</p>
                                        <p>Top to bottom.</p>
                                        <p>Let our technology impress you too!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;