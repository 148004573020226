import React, { Component } from "react";

class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">A technology <br /> hub. </h1>
                            </div>
                        </div>
                    </div>
                    {/* Start Service Area */}
                    <div className="row service-wrapper service-white">
                        <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                            <div className="service service__style--1">
                                <div className="content light">
                                    <h3 className="title rn-text-white">Branding, performance, marketing and business strategies.</h3>
                                    <h4 className="rn-text-white">All in one place, intelligently working together to ensure your interests are in perfect sync.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Service Area */}
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;